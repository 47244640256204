import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import * as ColorUtils from '../components/color-utils'
import { graphql } from 'gatsby'
const months = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];

const EventItem = ({ date, title, description, index }) => (
	<div className='event'>
		<div className='date'>
			<span className='day'>{date.getDate()}</span>
			<span className='month'>{months[date.getMonth()].toUpperCase()}</span>
			<span className='year'>{date.getFullYear()}</span>
		</div>
		<div className='event-description'>
			<div className='title'>
				{title}
			</div>
			<div className='description'>
				{description}
			</div>
		</div>
	</div>
	);

// eslint-disable-next-line
export const EventsPageTemplate = class extends React.Component {
	constructor(props) {
    super(props)
		const today = new Date();
		today.setHours(0,0,0,0);
    this.state = {
      filteredEvents: this.props.data.eventItems
			.filter(event => event.title !== 'Placeholder event [do not delete]')
			.filter(event => new Date(event.date) >= today)
			.sort(function(a,b){
				// Turn your strings into dates, and then subtract them
				// to get a value that is either negative, positive, or zero.
				return new Date(a.date) - new Date(b.date);
			})
    }
  }

	componentDidMount() {
		ColorUtils.setLightColor();
	}

	render() {
		return (
      <section className='container-xxl container-padding'>
				<div className="row pb-5 pt-6">
					{this.state.filteredEvents && this.state.filteredEvents.length > 0 ? 
					this.state.filteredEvents.map((event, index) => {
						return <EventItem key={index} title={event.title} description={event.description} date={new Date(event.date)}/>
					}) : <div className='col-12 no-events'><h2>Geen evenementen gepland!</h2></div>}
				</div>
      </section>
    );
  }
}

EventsPageTemplate.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

const EventsPage = ({data}) => {
  const { frontmatter } = data.markdownRemark;
	return (
		<Layout pageTitle='Fest • Events'>
			<EventsPageTemplate data={frontmatter}/>
		</Layout>
	)
};

EventsPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default EventsPage

export const pageQuery = graphql`
  query EventsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "events-page" } }) {
      frontmatter {
        title
        eventItems {
          date
          title
          description
        }
      }
    }
  }
`;
